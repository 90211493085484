// This bootstrap bootstrap file is for Security Login and Reset Password pages
// Check out ../vue/app.js for the bootstrap of the Inertia/Vue pages!

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap'
import '../style/borderless-floating.scss'
import '../style/app.scss'
import '../style/login.scss'

window.bootstrap = require("bootstrap")



